import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Flex, Box } from '@qga/roo-ui/components';
import { getCampaignName, getCampaignTitle, getCampaignMessage } from 'store/campaign/campaignSelectors';
import CampaignMessageLink from './CampaignMessageLink';
import { BannerText } from './primitives';
import { CAMPAIGN_BANNER_ENABLED } from 'config';
import { getTreatments } from 'store/split/splitSelectors';
import { getIsExclusive } from 'store/property/propertySelectors';
import useViewPromotionEvent from 'hooks/useViewPromotionEvent';
import { useBreakpoints } from 'hooks/useBreakpoints';

const DEFAULT_TITLE = 'Enjoy more Classic Hotel Rewards';
const DEFAULT_MESSAGE = `when you use points.`;

const CampaignMessaging = ({ showDefaultMessage, ...rest }) => {
  const ref = useRef(null);
  const treatments = useSelector(getTreatments);
  const name = useSelector(getCampaignName);
  const title = useSelector(getCampaignTitle);
  const message = useSelector(getCampaignMessage);
  const { isGreaterThanOrEqualToBreakpoint } = useBreakpoints();
  const isNotMobile = isGreaterThanOrEqualToBreakpoint(0);
  const hasCampaign = !!title;
  const titleToDisplay = hasCampaign ? title : DEFAULT_TITLE;
  const messageToDisplay = hasCampaign ? message : DEFAULT_MESSAGE;
  const isVppEnabled = treatments?.vpp?.treatment === 'on';
  const isExclusive = useSelector(getIsExclusive);
  const showCampaignBanner = (hasCampaign || showDefaultMessage) && !(isVppEnabled && isExclusive);
  useViewPromotionEvent({
    ref,
    promotion: {
      name,
      slot: 'banner',
    },
  });

  if (!showCampaignBanner || !CAMPAIGN_BANNER_ENABLED) return null;

  return (
    <Box {...rest} ref={ref} data-testid="campaign-banner-wrapper">
      <Flex bg="bayBlue30" justifyContent="center" alignItems="center" p={3} flexDirection={['column', 'row']}>
        <CampaignMessageLink title={titleToDisplay}>
          <BannerText data-testid="title">{titleToDisplay}</BannerText>
          {!isEmpty(messageToDisplay) && (
            <>
              {isNotMobile && (
                <BannerText mx="1" xs>
                  -
                </BannerText>
              )}
              <BannerText data-testid="message">{messageToDisplay}</BannerText>
            </>
          )}
        </CampaignMessageLink>
      </Flex>
    </Box>
  );
};

CampaignMessaging.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  showDefaultMessage: PropTypes.bool,
};

CampaignMessaging.defaultProps = {
  title: null,
  message: null,
  showDefaultMessage: true,
};

export default CampaignMessaging;
